<template>
<!--  <div>-->
<!--    <el-container>-->
<!--      &lt;!&ndash;      侧边栏&ndash;&gt;-->
<!--      <el-aside width="200px"><Aside /></el-aside>-->
<!--      <el-container>-->
<!--        &lt;!&ndash;    头部&ndash;&gt;-->
<!--        <el-header class="el_header"><Header /></el-header>-->
<!--        &lt;!&ndash;      内容区域&ndash;&gt;-->
<!--        <el-main><router-view /></el-main>-->
<!--      </el-container>-->
<!--    </el-container>-->
<!--  </div>-->
  <div>
    <div style="display: flex">
<!--      菜单栏-->
      <div style="display: flex;flex-direction:column;width: 270px;position:fixed">
        <div style="height: auto;background: #20222A;color: #F7F9F9;font-size: 20px;padding: 13px;font-weight: bold">成华诊所药店后台管理平台</div>
        <div style="background: #20222A"><Aside /></div>
      </div>
<!--      头部和主体区域-->
      <div style="flex: 1;flex-direction:column;background: #F0F2F5;margin-left: 300px">
        <div style="height: 60px"><Header /></div>
        <div style="margin: 10px 10px 0 10px;"><router-view /></div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Aside from "@/components/Aside";

export default {
  name: "Layout",
  components: {
    Header,
    Aside
  }
}
</script>

<style scoped>

.el_header {
  padding: 0;
}

</style>