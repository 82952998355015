<template>
  <div style="height: 60px; line-height: 50px; display: flex;background: #fff">
    <div style="flex: 1"></div>
    <div style="width: 180px;cursor: pointer" >
      <el-dropdown style="vertical-align: middle;color: #000;">
        <span class="el-dropdown-link" >
          欢迎您，{{ user.account }}
          <el-icon><arrow-down/></el-icon>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "Header",
  props: ['user'],
  data() {
    return {
      user:sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : {},

    }
  },
  created() {

  },
  methods: {
    logout() {
      sessionStorage.removeItem('user')
      router.push('/login')
    }
  }
}
</script>

<style scoped>

</style>
